import React from "react";
import { IoMdClose } from "react-icons/io";
import {
  FaGift,
  FaHandsHelping,
  FaRegCopy,
  FaUser,
  FaFlag,
  FaQrcode,
} from "react-icons/fa";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import {
  BiLogOutCircle,
  BiDollarCircle,
  BiChevronLeft,
  BiChevronRight,
  BiChevronDown,
  BiChevronUp,
  BiGitPullRequest,
} from "react-icons/bi";
import {
  AiOutlineUnorderedList,
  AiFillSetting,
  AiOutlineStar,
  AiFillStar,
} from "react-icons/ai";
import {
  MdDashboard,
  MdDelete,
  MdEdit,
  MdOutlineManageAccounts,
  MdAccountBox,
  MdMyLocation,
} from "react-icons/md";
import { FiSave } from "react-icons/fi";
import { FcTimeline } from "react-icons/fc";
import { GrTransaction } from "react-icons/gr";
import { RiUpload2Line } from "react-icons/ri";
import { IoMdMore } from "react-icons/io";
import { FiMoreVertical } from "react-icons/fi";

export const reactIcons = {
  user: <FaUser />,
  save: <FiSave />,
  logout: <BiLogOutCircle />,
  dollar: <BiDollarCircle />,
  list: <AiOutlineUnorderedList />,
  arrowleft: <BiChevronLeft />,
  arrowright: <BiChevronRight />,
  arrowDown: <BiChevronDown />,
  arrowUp: <BiChevronUp />,
  dashboard: <MdDashboard />,
  setting: <AiFillSetting />,
  edit: <MdEdit />,
  delete: <MdDelete />,
  manage: <MdOutlineManageAccounts />,
  gift: <FaGift />,
  star: <AiOutlineStar />,
  fillstar: <AiFillStar />,
  eyeVisible: <AiFillEye />,
  eyeInVisible: <AiFillEyeInvisible />,
  close: <IoMdClose />,
  copy: <FaRegCopy />,
  handShake: <FaHandsHelping />,
  timeline: <FcTimeline />,
  transactions: <GrTransaction />,
  request: <BiGitPullRequest />,
  flag: <FaFlag />,
  qrCode: <FaQrcode />,
  account: <MdAccountBox />,
  upload: <RiUpload2Line />,
  more: <FiMoreVertical />,
  moreItems: <IoMdMore />,
  location: <MdMyLocation />,
};
