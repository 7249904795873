export const contracts = {
  testnet: {
    fxfToken: "0x954c545591c9af13594f3A77a75853046Cee4961",
    tokenExchange: "0xaF203E108e4251D9A3dA8a9b391d4c84a68Dc826",
    tokenAirdrop: "0x80812d253C7EE9a596189C9539d25E6674177AC9",
    escrow: "0x4776fE535fac15105B59E8489b19720E41E7Dfcb",
    PlatformAdminAddress: "0x4e4a74E14F51c7fA9C1a83E43Ac073A374861132",
    partnerExchange: "0xd70bf3a7E594653FDCD0a2bB21081FEf102F8B84"
  },
  mainnet: {
    // fxfToken: "0x6892ef274f6ec75c998b73896b566e5eca036cfa",
    fxfToken: "0x7a72199b63f0c19e07f501e28029acd94eb17ee1",
    tokenExchange: "0xc217788Aedd7e4Bfaa8F9939bb95e41AD8ccf612",
    tokenAirdrop: "0xEd2125b7F51c6e28cfED3cB02109DBF4B4940936",
    escrow: "0x44b9e700B3B092420285cafB7b716fE5cC753Fa4",
    PlatformAdminAddress: "0x7A72199B63f0C19e07f501e28029acD94Eb17ee1",
    // PlatformAdminAddress: "0x6F2BC87Dbf65736C7BF48FE933DDbB58f7B05983",
    partnerExchange: "0xAA85F50251e23DFA75C5A5AcFd593097eFd36704"
  },
};
export const chain =
  process.env.REACT_APP_NETWORK === "testnet" ? "mumbai" : "polygon";


